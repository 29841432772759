import React from 'react'
import { Link, graphql } from 'gatsby'

import { mapEdgesToNodes, filterOutDocsWithoutSlugs, buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import GraphQLErrorList from '../components/graphql-error-list'
import BlockContent from '../components/block-content'
import SEO from '../components/seo'

import Layout from '../containers/layout'

export const query = graphql`
  query InsightPageQuery {
    insight: sanityInsightsPage(_id: { regex: "/(drafts.|)insightsPage/" }) {
      title
      title1
      body
      slide1Image {
        asset {
          _id
        }
      }
    }

    insights: allSanityInsight(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const ParagraphClass = 'dib relative w-100 measure-wide dark-gray fw4 mb4'
const HeadingClass = 'dib relative w-100 measure f2 fw5 mv3'

const InsightsPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.insight
  const insights =
    data && data.insights && mapEdgesToNodes(data.insights).filter(filterOutDocsWithoutSlugs)

  return (
    <Layout>
      <SEO title="Insights" />
      <article>
        <section className="dib relative w-100 bg-white black lh-copy f4" id="insights">
          <div className="db center mw8 ph4 pt6 pb4">
            <h1 className={HeadingClass}>{page.title1}</h1>
            <p className={ParagraphClass}>{page.body}</p>
            {page.slide1Image && page.slide1Image.asset && page.slide1Image.asset._id ? (
              <img
                className="dib relative w-100 pb2"
                src={imageUrlFor(buildImageObj(page.slide1Image))}
                alt={page.title1}
              />
            ) : null}
            {insights && insights.length > 0 ? (
              <div className="dib relative w-100">
                {insights.map((object, index) => (
                  <Link
                    className="dib relative w-100 measure-wide pv4 link dim inherit bb b--black-10"
                    key={`insight-${index}`}
                    to={`/insights/${object.slug.current}`}
                  >
                    <div className="dib flex-l justify-between items-center">
                      {object.mainImage && object.mainImage.asset && object.mainImage.asset._id ? (
                        <div
                          className="dib relative w-100 w-40-l fit mr4 br3 lawyer-pic"
                          style={{
                            background: `url(${imageUrlFor(
                              buildImageObj(object.mainImage)
                            )}) no-repeat center center / cover`
                          }}
                        >
                          &nbsp;
                        </div>
                      ) : (
                        <div
                          className="dib relative w-100 w-40-l fit mr4 br3 lawyer-pic"
                          style={{
                            background: `url('/img/empty-work.png') no-repeat center center / cover`
                          }}
                        >
                          &nbsp;
                        </div>
                      )}
                      <div className="dib relative w-100 w-60-l">
                        <strong className="dib relative w-100 f4 b">{object.title}</strong>
                        {object._rawExcerpt ? (
                          <div className="dib relative w-100 measure-wide dark-gray fw4 f5 mv0 hidden-content">
                            <BlockContent blocks={object._rawExcerpt} />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ) : null}
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default InsightsPage
